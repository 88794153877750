var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.title,
            width: "850px",
            top: "5vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { ref: "dialogContent", staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加图片:", prop: "pic" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "130px",
                            height: "130px",
                            background: "#f9f9f9",
                            "border-radius": "4px",
                            border: "1px solid #eeeeee",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "show-file-list": false,
                                action: "",
                                "http-request": _vm.handleFileUpload,
                              },
                            },
                            [
                              _vm.ruleForm.pic
                                ? _c("img", {
                                    staticClass: "avatar_img",
                                    attrs: { src: _vm.ruleForm.pic },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pointer",
                          staticStyle: { color: "#02a7f0" },
                          on: { click: _vm.onGetActivePictures },
                        },
                        [_vm._v("\n            获取活动图片\n          ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置:", prop: "locationCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: { placeholder: "请选择位置" },
                          on: { change: _vm.locationCodeChange },
                          model: {
                            value: _vm.ruleForm.locationCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "locationCode", $$v)
                            },
                            expression: "ruleForm.locationCode",
                          },
                        },
                        _vm._l(_vm.marketingBitList, function (item) {
                          return _c("el-option", {
                            key: item.locationCode,
                            attrs: {
                              label: item.locationName,
                              value: item.locationCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态:", prop: "enable" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: { placeholder: "请选择上下线" },
                          model: {
                            value: _vm.ruleForm.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "enable", $$v)
                            },
                            expression: "ruleForm.enable",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "上线", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "下线", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序:", prop: "sort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入排序" },
                        model: {
                          value: _vm.ruleForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                          },
                          expression: "ruleForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动跳转:", prop: "jumpType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.onJumpTypeToggle },
                          model: {
                            value: _vm.ruleForm.jumpType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "jumpType", $$v)
                            },
                            expression: "ruleForm.jumpType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("URL跳转"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("选择活动"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.jumpType === 2
                    ? _c("el-form-item", { attrs: { prop: "jumpContent" } }, [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入URL" },
                              model: {
                                value: _vm.ruleForm.jumpContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "jumpContent", $$v)
                                },
                                expression: "ruleForm.jumpContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.jumpType === 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "活动选择:", prop: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "Official_event",
                              on: { click: _vm.onOfficialEvent },
                            },
                            [_vm._v("去选择")]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm.presentationData.Display_type === 1 ||
                            _vm.presentationData.Display_type === 2 ||
                            _vm.presentationData.Display_type === 3 ||
                            _vm.presentationData.Display_type === 6 ||
                            _vm.presentationData.Display_type === 7 ||
                            _vm.presentationData.Display_type === 8
                              ? _c(
                                  "div",
                                  { staticClass: "movable_box" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "活动类型:",
                                          prop: "",
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.presentationData.activityType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.presentationData,
                                                "activityType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "presentationData.activityType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.presentationData.Display_type !== 3 &&
                                    _vm.presentationData.Display_type !== 7 &&
                                    _vm.presentationData.Display_type !== 8
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticClass: "mt10",
                                            attrs: {
                                              label: "平台类型:",
                                              prop: "",
                                              "label-width": "100px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.presentationData
                                                    .unionCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.presentationData,
                                                    "unionCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "presentationData.unionCode",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mt10",
                                        attrs: {
                                          label: "活动名称:",
                                          prop: "",
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.ruleForm.activityName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "activityName",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.activityName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mt10",
                                        attrs: {
                                          label:
                                            _vm.presentationData
                                              .Display_type === 6 ||
                                            _vm.presentationData
                                              .Display_type === 7
                                              ? "头图:"
                                              : "活动中转页:",
                                          prop: "",
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "200px",
                                            height: "100px",
                                            "border-radius": "10px",
                                            border: "1px solid #ccc",
                                          },
                                          attrs: {
                                            src: _vm.presentationData
                                              .activityImg,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.presentationData.Display_type !== 3 &&
                                    _vm.presentationData.Display_type !== 6 &&
                                    _vm.presentationData.Display_type !== 7
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "",
                                              prop: "",
                                              "label-width": "100px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm.interPageFlag,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "interPageFlag",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.interPageFlag",
                                                },
                                              },
                                              [_vm._v("启用中间页")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.presentationData.Display_type === 4 ||
                            _vm.presentationData.Display_type === 5
                              ? _c(
                                  "div",
                                  { staticClass: "movable_box" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "活动类型:",
                                          prop: "",
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.presentationData.activityType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.presentationData,
                                                "activityType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "presentationData.activityType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mt10",
                                        attrs: {
                                          label: "活动名称:",
                                          prop: "",
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.ruleForm.activityName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "activityName",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.activityName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间设置:", prop: "timeArray" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.timeArray,
                          callback: function ($$v) {
                            _vm.timeArray = $$v
                          },
                          expression: "timeArray",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ActivitySelectionPopup", {
        ref: "activitySelectionPopup",
        on: { SelectEvents: _vm.onSelectEvents },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }