"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAct = addAct;
exports.addOnegood = addOnegood;
exports.batchCancelTop = batchCancelTop;
exports.batchDelete = batchDelete;
exports.batchSetTag = batchSetTag;
exports.batchTop = batchTop;
exports.delAct = delAct;
exports.deleteCustomGoodsCategory = deleteCustomGoodsCategory;
exports.downTemplate = downTemplate;
exports.getCustomGoodsCategory = getCustomGoodsCategory;
exports.getImportResult = getImportResult;
exports.goodsUpdate = goodsUpdate;
exports.importGoodsTemplate = importGoodsTemplate;
exports.queryActList = queryActList;
exports.queryGoods = queryGoods;
exports.refGoods = refGoods;
exports.saveCustomGoodsCategory = saveCustomGoodsCategory;
exports.updateAct = updateAct;
exports.updateCustomGoodsCategory = updateCustomGoodsCategory;
var _request = _interopRequireDefault(require("@/utils/request"));
function addAct(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/activity/save',
    method: 'post',
    data: data
  });
}
function updateAct(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/activity/update',
    method: 'post',
    data: data
  });
}
function delAct(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/activity/delete',
    method: 'post',
    data: data
  });
}
function queryActList(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/activity/list',
    method: 'post',
    data: data
  });
}
function getImportResult(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/getImportResult',
    method: 'post',
    data: data
  });
}
function getCustomGoodsCategory(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/getCustomGoodsCategory',
    method: 'post',
    data: data
  });
}
function saveCustomGoodsCategory(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/saveCustomGoodsCategory',
    method: 'post',
    data: data
  });
}
function updateCustomGoodsCategory(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/updateCustomGoodsCategory',
    method: 'post',
    data: data
  });
}
function deleteCustomGoodsCategory(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/deleteCustomGoodsCategory',
    method: 'post',
    data: data
  });
}
function queryGoods(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/list',
    method: 'post',
    data: data
  });
}
function batchTop(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/batchTop',
    method: 'post',
    data: data
  });
}
function refGoods(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/refGoods',
    method: 'post',
    data: data
  });
}
function batchDelete(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/batchDelete',
    method: 'post',
    data: data
  });
}
function downTemplate(query) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/downTemplate',
    method: 'get',
    params: query
  });
}
function addOnegood(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/save',
    method: 'post',
    data: data
  });
}
function importGoodsTemplate(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/importGoodsTemplate',
    method: 'post',
    data: data
  });
}
function goodsUpdate(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/update',
    method: 'post',
    data: data
  });
}
function batchCancelTop(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/batchCancelTop',
    method: 'post',
    data: data
  });
}
function batchSetTag(data) {
  return (0, _request.default)({
    url: '/portal/system/custom/goods/batchSetTag',
    method: 'post',
    data: data
  });
}