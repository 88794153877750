"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageDeletePage = pageDeletePage;
exports.pageListPage = pageListPage;
exports.pageSavePage = pageSavePage;
exports.pageUpdatePage = pageUpdatePage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询页面列表
function pageListPage(params) {
  return (0, _request.default)({
    url: '/portal/system/custom/page/listPage',
    method: 'post',
    data: params
  });
}
// 保存页面
function pageSavePage(params) {
  return (0, _request.default)({
    url: '/portal/system/custom/page/savePage',
    method: 'post',
    data: params
  });
}
// 更新页面
function pageUpdatePage(params) {
  return (0, _request.default)({
    url: '/portal/system/custom/page/updatePage',
    method: 'post',
    data: params
  });
}
// 删除页面
function pageDeletePage(params) {
  return (0, _request.default)({
    url: '/portal/system/custom/page/deletePage',
    method: 'post',
    data: params
  });
}