"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryListApi = categoryListApi;
exports.deleteMaterialCategoryApi = deleteMaterialCategoryApi;
exports.deleteMaterialManagerApi = deleteMaterialManagerApi;
exports.getByIdApi = getByIdApi;
exports.getMaterialBaseConfigApi = getMaterialBaseConfigApi;
exports.getMaterialCategoryApi = getMaterialCategoryApi;
exports.getMaterialCategoryByIdApi = getMaterialCategoryByIdApi;
exports.getMaterialManagerApi = getMaterialManagerApi;
exports.materialUpdateStatus = materialUpdateStatus;
exports.saveMaterialBaseConfigApi = saveMaterialBaseConfigApi;
exports.saveMaterialCategoryApi = saveMaterialCategoryApi;
exports.saveMaterialManagerApi = saveMaterialManagerApi;
exports.updateMaterialBaseConfigApi = updateMaterialBaseConfigApi;
exports.updateMaterialCategoryApi = updateMaterialCategoryApi;
exports.updateMaterialManagerApi = updateMaterialManagerApi;
exports.updateStatusApi = updateStatusApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 发圈-个性化
// 查询素材圈基础配置
function getMaterialBaseConfigApi(params) {
  return (0, _request.default)({
    url: '/portal/system/material/baseConfig/getMaterialBaseConfig',
    method: 'get',
    params: params
  });
}
// 保存素材圈基础配置
function saveMaterialBaseConfigApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/baseConfig/saveMaterialBaseConfig',
    method: 'post',
    data: data
  });
}
// 修改素材圈基础配置
function updateMaterialBaseConfigApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/baseConfig/updateMaterialBaseConfig',
    method: 'post',
    data: data
  });
}

// 发圈素材*****************************************************************************************
// 查询素材圈管理
function getMaterialManagerApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/getMaterialManager',
    method: 'post',
    data: data
  });
}
// 保存素材圈管理
function saveMaterialManagerApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/saveMaterialManager',
    method: 'post',
    data: data
  });
}
// 修改素材圈管理
function updateMaterialManagerApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/updateMaterialManager',
    method: 'post',
    data: data
  });
}
// 删除素材圈管理
function deleteMaterialManagerApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/deleteMaterialManager',
    method: 'post',
    data: data
  });
}
// 获取素材圈单个配置
function getByIdApi(params) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/getById',
    method: 'get',
    params: params
  });
}
// 上下线
function materialUpdateStatus(data) {
  return (0, _request.default)({
    url: '/portal/system/material/manager/updateStatus',
    method: 'post',
    data: data
  });
}
// 发圈分类********************************************************************************************
// 删除素材圈分类配置
function deleteMaterialCategoryApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/category/deleteMaterialCategory',
    method: 'post',
    data: data
  });
}
// 分类上下线
function updateStatusApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/category/updateStatus',
    method: 'post',
    data: data
  });
}
// 查询所属子节点分类配置
function getMaterialCategoryApi(params) {
  return (0, _request.default)({
    url: '/portal/system/material/category/getMaterialCategory',
    method: 'get',
    params: params
  });
}
// 查询所有配置
function categoryListApi(params) {
  return (0, _request.default)({
    url: '/portal/system/material/category/list',
    method: 'get',
    params: params
  });
}

// 保存素材圈分类配置
function saveMaterialCategoryApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/category/saveMaterialCategory',
    method: 'post',
    data: data
  });
}
// 修改素材圈分类配置
function updateMaterialCategoryApi(data) {
  return (0, _request.default)({
    url: '/portal/system/material/category/updateMaterialCategory',
    method: 'post',
    data: data
  });
}
// 查询单个分类配置
function getMaterialCategoryByIdApi(params) {
  return (0, _request.default)({
    url: '/portal/system/material/category/getMaterialCategoryById',
    method: 'get',
    params: params
  });
}