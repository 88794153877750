"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/core-js/modules/es6.array.iterator.js");
require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/core-js/modules/es6.promise.js");
require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/core-js/modules/es6.object.assign.js");
require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
// A modern alternative to CSS resets

// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// global css

// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// 注释mock服务器代码 modify by daiysh 20200222 16:45
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明

_vue.default.use(_vueClipboard.default);
_vue.default.use(_elementUi.default);
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});