"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _generalSituation = _interopRequireDefault(require("./components/generalSituation.vue"));
var _RelationChain = _interopRequireDefault(require("./components/RelationChain.vue"));
var _Vermicelli = _interopRequireDefault(require("./components/Vermicelli.vue"));
var _PurchaseOrder = _interopRequireDefault(require("./components/PurchaseOrder.vue"));
var _PromotionOrder = _interopRequireDefault(require("./components/PromotionOrder.vue"));
var _PurchaseAccount = _interopRequireDefault(require("./components/PurchaseAccount.vue"));
var _PromotionAccount = _interopRequireDefault(require("./components/PromotionAccount.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserDetails',
  components: {
    generalSituation: _generalSituation.default,
    RelationChain: _RelationChain.default,
    Vermicelli: _Vermicelli.default,
    PurchaseOrder: _PurchaseOrder.default,
    PromotionOrder: _PromotionOrder.default,
    PurchaseAccount: _PurchaseAccount.default,
    PromotionAccount: _PromotionAccount.default
  },
  data: function data() {
    return {
      selectIndex: 'gk',
      nameList: [{
        name: '概况',
        id: 'gk'
      }, {
        name: '关系链',
        id: 'gxl'
      }, {
        name: '粉丝',
        id: 'fs'
      }, {
        name: '自购订单',
        id: 'zgdd'
      }, {
        name: '团队订单',
        id: 'tgdd'
      }, {
        name: '自购账户',
        id: 'zgzh'
      }, {
        name: '团队账户',
        id: 'tgzh'
      }],
      memberId: null,
      nickname: '',
      times: new Date().getSeconds()
    };
  },
  watch: {
    '$route': function $route(to, from) {
      // 对路由变化作出响应...
      if (to.name === 'userDetails' && from.name === 'userDetails') {
        this.memberId = Number(this.$route.query.memberId);
        this.nickname = this.$route.query.nickname;
        this.times = new Date().getSeconds(); // 刷新子组件
      }
    }
  },
  created: function created() {
    this.memberId = Number(this.$route.query.memberId);
    this.nickname = this.$route.query.nickname;
  },
  methods: {
    onSelect: function onSelect(value) {
      this.selectIndex = value.id;
    },
    onBlack: function onBlack() {
      this.$router.go(-1);
    },
    AccountDetails: function AccountDetails(type) {
      if (type === 'self') {
        this.selectIndex = 'zgzh';
      } else {
        this.selectIndex = 'tgzh';
      }
      console.log('AccountDetails', type);
    }
  }
};