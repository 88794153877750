var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fc", staticStyle: { "margin-top": "100px" } },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "300px", "padding-bottom": "50px" } },
        _vm._l(_vm.RelationChinaDate, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "relation_chain flex pointer",
              on: {
                click: function ($event) {
                  return _vm.onSelect(item)
                },
              },
            },
            [
              _c("div", { staticClass: "relation_box" }, [
                _c(
                  "div",
                  {
                    staticClass: "relation_box_div fc",
                    class:
                      index === _vm.RelationChinaDate.length - 1
                        ? "bg_D8AFF"
                        : "bg_fff",
                  },
                  [_vm._v(_vm._s(index + 1))]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ml20 fc" }, [
                _c(
                  "span",
                  {
                    staticClass: "span1",
                    class:
                      index === _vm.RelationChinaDate.length - 1
                        ? "color-4D8AFF"
                        : "color-96",
                  },
                  [_vm._v(_vm._s(item.nickname))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "span2",
                    class:
                      index === _vm.RelationChinaDate.length - 1
                        ? "color-4D8AFF"
                        : "color-96",
                  },
                  [_vm._v("(" + _vm._s(item.gradeName) + ")")]
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }