"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    refreshToken: '',
    name: '',
    avatar: '',
    userId: '',
    appId: (0, _auth.getAppId)() || '',
    roles: [],
    breadList: sessionStorage.getItem('breadList') ? JSON.parse(sessionStorage.getItem('breadList')) : [],
    breadActiveNum: sessionStorage.getItem('breadActiveNum') ? JSON.parse(sessionStorage.getItem('breadActiveNum')) : 0,
    treeRouters: []
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USERID: function SET_USERID(state, userId) {
    state.userId = userId;
  },
  SET_APPID: function SET_APPID(state, appId) {
    state.appId = appId;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_BREADLIST: function SET_BREADLIST(state, breadList) {
    state.breadList = breadList;
  },
  SET_NUM: function SET_NUM(state, breadActiveNum) {
    state.breadActiveNum = String(breadActiveNum);
  },
  // 接口返回的路由
  SET_TREE_ROUTERS: function SET_TREE_ROUTERS(state, treeRouters) {
    state.treeRouters = treeRouters;
  }
};
var actions = {
  set_appId: function set_appId(_ref, appId) {
    var commit = _ref.commit;
    commit('SET_APPID', appId);
    (0, _auth.setAppId)(appId);
  },
  // user login
  login: function login(_ref2, userInfo) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.login)(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.accessToken); // 存储token到仓库
        (0, _auth.setToken)(data.accessToken); // 存储token到cookie
        resolve(data.userType);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)().then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        // data.roles = data.roles.map(i => {
        //   return i.code
        // })
        var icon = data.icon,
          username = data.username,
          userId = data.userId,
          tree = data.tree;
        // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!')
        // }
        commit('SET_USERID', userId);
        // commit('SET_ROLES', roles)
        commit('SET_TREE_ROUTERS', (0, _auth.transformTreeToRoutes)(tree)); // 存储接口返回的路由
        commit('SET_NAME', username);
        commit('SET_AVATAR', icon);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        (0, _auth.removeToken)(); // must remove  token  first
        (0, _auth.removeAppId)(); // remove appId
        (0, _router.resetRouter)();
        commit('RESET_STATE');
        commit('SET_TREE_ROUTERS', []); // 存储接口返回的路由
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};