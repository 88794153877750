"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditWithdrawApply = auditWithdrawApply;
exports.batchAuditApplyManual = batchAuditApplyManual;
exports.listWithdrawApplyPaged = listWithdrawApplyPaged;
exports.queryWithdrawRule = queryWithdrawRule;
exports.updateWithdrawRule = updateWithdrawRule;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员提现管理----提现列表
function listWithdrawApplyPaged(params) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/listWithdrawApplyPaged',
    method: 'get',
    params: params
  });
}
// =================================================================
// 审核提现
function auditWithdrawApply(data) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/auditApplyManual',
    method: 'post',
    data: data
  });
}
// export function auditWithdrawApply(data) {
//   return request({
//     url: '/portal/system/withdraw/auditWithdrawApply',
//     method: 'post',
//     data
//   })
// }
// 提现规则
function queryWithdrawRule(params) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/queryWithdrawRule',
    method: 'get',
    params: params
  });
}

// 编辑提现
function updateWithdrawRule(data) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/updateWithdrawRule',
    method: 'post',
    data: data
  });
}
// 批量审核
function batchAuditApplyManual(data) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/batchAuditApplyManual',
    method: 'post',
    data: data
  });
}