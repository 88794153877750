var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "both-pd-box" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c("h3", [_vm._v("第三方代下单")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    background: "#02c58d",
                    border: "none",
                    "margin-left": "7px",
                    "border-radius": "8px",
                  },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "right" } }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-input", {
            staticStyle: { width: "300px", "margin-right": "10px" },
            attrs: { placeholder: "请输入活动名称", clearable: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.search()
                },
              },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    width: "19%",
                    "margin-right": "1%",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "0px" },
                        shadow: "hover",
                      },
                    },
                    [
                      item.platformCode === "QF"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "line-height": "150px",
                                "font-weight": "bold",
                                "text-align": "center",
                                "font-size": "22px",
                                "border-bottom": "1px solid #f1f1f1",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c("el-image", {
                            staticStyle: { width: "100%", height: "150px" },
                            attrs: {
                              src: item.mainImage,
                              fit: "cover",
                              "preview-src-list": [item.mainImage],
                            },
                          }),
                      _vm._v(" "),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.platformCode === "QF" ? "充值" : item.name
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "long-text",
                                staticStyle: { flex: "1" },
                              },
                              [_vm._v(_vm._s(item.openUrl))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "30%",
                                  "text-align": "right",
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                  staticStyle: {
                                    color: "#1890FF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copy(item.openUrl)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: { underline: false },
                                    on: {
                                      click: function ($event) {
                                        return _vm.details(item)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: { underline: false },
                                    on: {
                                      click: function ($event) {
                                        return _vm.del(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("el-empty", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.list.length === 0,
                expression: "list.length===0",
              },
            ],
            attrs: { "image-size": 200 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pagination-container",
          staticStyle: { margin: "20px 0", "text-align": "right" },
        },
        [
          _c("el-pagination", {
            staticStyle: { display: "inline-block" },
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.limit,
              total: _vm.total,
              background: "",
              layout: "total, prev, pager, next, sizes,jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("addDialog", { ref: "addDialog", on: { refresh: _vm.refresh } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }