"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteClassSetApi = deleteClassSetApi;
exports.getClassSetApi = getClassSetApi;
exports.getRegionApi = getRegionApi;
exports.listClassSetApi = listClassSetApi;
exports.moveClassSetApi = moveClassSetApi;
exports.saveClassSetApi = saveClassSetApi;
exports.updateClassSetApi = updateClassSetApi;
exports.updateRegionApi = updateRegionApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 等级配置
// 配置项列表
function listClassSetApi(params) {
  return (0, _request.default)({
    url: '/portal/system/class/listClassSet',
    method: 'get',
    params: params
  });
}

// 创建等级配置项
function saveClassSetApi(data) {
  return (0, _request.default)({
    url: '/portal/system/class/saveClassSet',
    method: 'post',
    data: data
  });
}
// 等级配置项详情
function getClassSetApi(params) {
  return (0, _request.default)({
    url: '/portal/system/class/getClassSet',
    method: 'get',
    params: params
  });
}
// 更新等级配置项
function updateClassSetApi(data) {
  return (0, _request.default)({
    url: '/portal/system/class/updateClassSet',
    method: 'post',
    data: data
  });
}
// 删除等级配置项
function deleteClassSetApi(data) {
  return (0, _request.default)({
    url: '/portal/system/class/deleteClassSet',
    method: 'post',
    data: data
  });
}
// 拖拽等级配置项
function moveClassSetApi(data) {
  return (0, _request.default)({
    url: '/portal/system/class/moveClassSet',
    method: 'post',
    data: data
  });
}

// 区域分佣配置*************************************
// 查询区域配置
function getRegionApi(params) {
  return (0, _request.default)({
    url: '/portal/system/tenant/getRegion',
    method: 'get',
    params: params
  });
}
// 更新区域配置
function updateRegionApi(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/updateRegion',
    method: 'post',
    data: data
  });
}