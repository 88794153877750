var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "both-pd-box" },
    [
      _c("h3", [_vm._v("首单补贴活动设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            color: "orange",
            "margin-left": "20px",
          },
        },
        [
          _vm._v(
            "\n    注：首单补贴活动，指面向目标群体为在平台注册后未下有效订单用户的首单补贴活动\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            rules: _vm.rules,
            model: _vm.ruleForm,
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "功能设置:" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.radio,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "radio", $$v)
                    },
                    expression: "ruleForm.radio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "单个用户补贴次数:", prop: "num" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "numrule",
                  staticStyle: { width: "50%" },
                  attrs: {
                    clearable: "",
                    type: "number",
                    placeholder: "请输入内容",
                  },
                  model: {
                    value: _vm.ruleForm.num,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "num", _vm._n($$v))
                    },
                    expression: "ruleForm.num",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("次")])],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "首单补贴金:", prop: "money" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "numrule",
                  staticStyle: { width: "50%" },
                  attrs: {
                    clearable: "",
                    type: "number",
                    placeholder: "请输入内容",
                  },
                  model: {
                    value: _vm.ruleForm.money,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "money", _vm._n($$v))
                    },
                    expression: "ruleForm.money",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "12px",
                    "margin-top": "20px",
                  },
                },
                [_vm._v("补贴规则")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(
                  "1.用户下单后，若实际付款金额 减去 基础佣金 (即分享赚) 后的所得金额 ≥ 首单补贴金，就返“首单补贴金”金额。"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(
                  '2.若上述条件相减后的所得金额＜首单补贴金，就返"相减后的所得金额”。'
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(
                  "3.补贴金额以返佣的形式补贴用户，会与基础佣金 (即分享赚) 相加后，展示在商品订单的“预估佣金”内。"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间:", prop: "time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.ruleForm.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "time", $$v)
                  },
                  expression: "ruleForm.time",
                },
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v("注：超出设定时间范围后，活动自动关闭"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("立即创建")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }