"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrUpdateAppUser = addOrUpdateAppUser;
exports.deleteUser = deleteUser;
exports.queryRolesByAppId = queryRolesByAppId;
exports.queryUserCanDelete = queryUserCanDelete;
exports.queryUserInfo = queryUserInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
// [new]应用端添加或修改用户
function addOrUpdateAppUser(data) {
  return (0, _request.default)({
    url: '/portal/system/user/addOrUpdateAppUser',
    method: 'post',
    data: data
  });
}
// [new]小B端所有角色
function queryRolesByAppId(params) {
  return (0, _request.default)({
    url: '/portal/system/role/queryRolesByAppId',
    method: 'get',
    params: params
  });
}
// [new]通用用户详情
function queryUserInfo(params) {
  return (0, _request.default)({
    url: '/portal/system/user/queryUserInfo',
    method: 'get',
    params: params
  });
}
// [new]删除用户
function deleteUser(data) {
  return (0, _request.default)({
    url: '/portal/system/user/deleteUser',
    method: 'post',
    data: data
  });
}
// [new]获取登陆用户删除权限
function queryUserCanDelete(params) {
  return (0, _request.default)({
    url: '/portal/system/auth/queryUserCanDelete',
    method: 'get',
    params: params
  });
}