"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deletePosterApi = deletePosterApi;
exports.getPosterApi = getPosterApi;
exports.listPosterApi = listPosterApi;
exports.savePosterApi = savePosterApi;
exports.updatePosterApi = updatePosterApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 保存海报
function savePosterApi(data) {
  return (0, _request.default)({
    url: '/portal/system/poster/savePoster',
    method: 'post',
    data: data
  });
}
function listPosterApi(data) {
  return (0, _request.default)({
    url: '/portal/system/poster/listPoster',
    method: 'post',
    data: data
  });
}
// 删除
function deletePosterApi(params) {
  return (0, _request.default)({
    url: '/portal/system/poster/deletePoster',
    method: 'get',
    params: params
  });
}
// 查询
function getPosterApi(params) {
  return (0, _request.default)({
    url: '/portal/system/poster/getPoster',
    method: 'get',
    params: params
  });
}
// 编辑
function updatePosterApi(data) {
  return (0, _request.default)({
    url: '/portal/system/poster/updatePoster',
    method: 'post',
    data: data
  });
}