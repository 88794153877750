var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recordDetails" }, [
    _c("div", { staticClass: "header_box" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-arrow-left" },
              on: { click: _vm.onBlack },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "name_span" }, [_vm._v("淘礼金详情")]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { padding: "20px 80px 0px 20px" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticStyle: { margin: "20px 100px" } }, [
        _c("div", { staticClass: "flex text_title" }, [
          _vm._v("\n        创建用户名：\n        "),
          _c(
            "div",
            {
              staticClass: "pointer",
              staticStyle: { "font-size": "14px", color: "#4d8aff" },
              on: {
                click: function ($event) {
                  return _vm.onUserDetails(_vm.userData)
                },
              },
            },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.userData.nickName) + "\n        "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text_title" }, [
          _vm._v(
            "\n        用户ID：\n        " +
              _vm._s(_vm.userData.memberId) +
              "\n        "
          ),
          _c("img", {
            staticClass: "pointer",
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-left": "10px",
            },
            attrs: { src: require("@/assets/copy_icon.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.copyText(_vm.userData.memberId)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text_title" }, [
          _vm._v(
            "\n        淘礼金ID：" + _vm._s(_vm.userData.id) + "\n        "
          ),
          _c("img", {
            staticClass: "pointer",
            staticStyle: {
              width: "14px",
              height: "14px",
              "margin-left": "10px",
            },
            attrs: { src: require("@/assets/copy_icon.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.copyText(_vm.userData.goodsDetailVo.id)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex text_title mt_top" }, [
          _vm._v("\n        淘礼金状态：\n        "),
          Number(_vm.userData.status) === 1
            ? _c("div", [_vm._v("进行中")])
            : _vm._e(),
          _vm._v(" "),
          Number(_vm.userData.status) === 2
            ? _c("div", [_vm._v("待生效")])
            : _vm._e(),
          _vm._v(" "),
          Number(_vm.userData.status) === 3
            ? _c("div", [_vm._v("创建失败")])
            : _vm._e(),
          _vm._v(" "),
          Number(_vm.userData.status) === 4
            ? _c("div", [_vm._v("已过期")])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "0px 80px 0px 20px", "margin-top": "30px" } },
      [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { "margin-top": "30px" } },
          [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "border-radius": "10px",
                },
                attrs: { src: _vm.userData.goodsDetailVo.picUrl, alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ml10 text_title" }, [
              _c("div", [_vm._v(_vm._s(_vm.userData.goodsDetailVo.title))]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "店铺名称：" + _vm._s(_vm.userData.goodsDetailVo.shopTitle)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "\n          商品ID：" +
                    _vm._s(_vm.userData.goodsDetailVo.id) +
                    "\n          "
                ),
                _c("img", {
                  staticClass: "pointer",
                  staticStyle: {
                    width: "14px",
                    height: "14px",
                    "margin-left": "10px",
                  },
                  attrs: { src: require("@/assets/copy_icon.png"), alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.copyText(_vm.userData.goodsDetailVo.id)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "\n          商品金额：" +
                    _vm._s(_vm.userData.goodsDetailVo.actualPrice) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v("创建时间：" + _vm._s(_vm.userData.createTime)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v("单个礼金金额：￥" + _vm._s(_vm.userData.perFace)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "礼金使用门槛：￥" + _vm._s(_vm.userData.tljUseThreshold)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v("礼金创建个数：" + _vm._s(_vm.userData.quantity) + "个"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "单个用户领取上限：" +
                    _vm._s(_vm.userData.useLimitCount) +
                    "个"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "创建总金额：￥" +
                    _vm._s(_vm.userData.perFace * _vm.userData.quantity)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex mt_top" }, [
                _vm._v("\n          领取时间：\n          "),
                Number(_vm.userData.useEndTimeMode) === 2
                  ? _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.userData.useStartTime) +
                          "-" +
                          _vm._s(_vm.userData.useEndTime) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              Number(_vm.userData.useEndTimeMode) === 1
                ? _c("div", { staticClass: "mt_top" }, [
                    _vm._v(
                      "\n          领取后" +
                        _vm._s(_vm.userData.useEndTime) +
                        "天可用\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt_top" }, [
                _vm._v(
                  "\n          使用时间：" +
                    _vm._s(_vm.userData.sendStartTime) +
                    " 至 " +
                    _vm._s(_vm.userData.sendEndTime) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              Number(_vm.userData.createStatus) === 2
                ? _c("div", [
                    _vm._v(
                      "\n          停用时间：" +
                        _vm._s(_vm.userData.updateTime) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text_title",
        staticStyle: { padding: "0px 80px 40px 20px", "margin-top": "30px" },
      },
      [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticStyle: { margin: "20px 100px" } }, [
          _c("div", [
            _vm._v("已领取数：" + _vm._s(_vm.UseDetail.winPv) + "个"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("已领取金额：" + _vm._s(_vm.UseDetail.winSumAmt) + "元"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("领取率：" + _vm._s(_vm.UseDetail.getRate) + "%"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("未领取数：" + _vm._s(_vm.UseDetail.remainingNum) + "个"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("已使用个数:" + _vm._s(_vm.UseDetail.useNum) + "个"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("礼金订单数：" + _vm._s(_vm.UseDetail.alipayNum)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("礼金订单金额：" + _vm._s(_vm.UseDetail.alipayAmt) + "元"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("使用率：" + _vm._s(_vm.UseDetail.useRate) + "%"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt_top" }, [
            _vm._v("实际使用金额：" + _vm._s(_vm.UseDetail.useSumAmt) + "元"),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("基础信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("创建信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("发放数据信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }