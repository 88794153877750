"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTenant = addTenant;
exports.queryUserPageWhileCreateApp = queryUserPageWhileCreateApp;
var _request = _interopRequireDefault(require("@/utils/request"));
function addTenant(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/addTenant',
    method: 'post',
    data: data
  });
}
function queryUserPageWhileCreateApp(data) {
  return (0, _request.default)({
    url: '/portal/system/user/queryUserPageWhileCreateApp',
    method: 'post',
    data: data
  });
}