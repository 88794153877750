"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryMemberSuggestsApi = queryMemberSuggestsApi;
exports.suggestRecoverApi = suggestRecoverApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 用户反馈列表
function queryMemberSuggestsApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberSuggests',
    method: 'post',
    data: data
  });
}
// 意见回复
function suggestRecoverApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/suggestRecover',
    method: 'post',
    data: data
  });
}