var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "memberBalanceDetails" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "会员余额明细", name: "first" } },
            [_c("BalanceDetail")],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "拼多多订单导入", name: "second" } },
                [_c("PddOrderEntry")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }