var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible1,
            "close-on-click-modal": false,
            title: _vm.title,
            width: "40%",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm15",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动类型", prop: "platformCode" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.platformCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "platformCode", $$v)
                            },
                            expression: "ruleForm.platformCode",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "OT" } }, [
                            _vm._v("点单"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "QF" } }, [
                            _vm._v("充值"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转类型", prop: "radio" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.radio,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "radio", $$v)
                            },
                            expression: "ruleForm.radio",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("ID跳转"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("URL跳转"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "typeText" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.typeText,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "typeText", $$v)
                          },
                          expression: "ruleForm.typeText",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.platformCode === "OT"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "头图", prop: "headPhoto" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "130px",
                                height: "130px",
                                background: "#f9f9f9",
                                "border-radius": "4px",
                                border: "1px solid #eeeeee",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    "show-file-list": false,
                                    action: "",
                                    "http-request": _vm.handleFileUpload,
                                  },
                                },
                                [
                                  _vm.ruleForm.headPhoto
                                    ? _c("img", {
                                        staticClass: "avatar_img",
                                        attrs: { src: _vm.ruleForm.headPhoto },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "40px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm15")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm15")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }