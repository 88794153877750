"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBusinessCollege = getBusinessCollege;
exports.marketingGetById = marketingGetById;
exports.marketingList = marketingList;
exports.marketingSave = marketingSave;
exports.marketingUpdate = marketingUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
// 二级营销位

// 获取该类型下所有的位置信息
function getBusinessCollege(params) {
  return (0, _request.default)({
    url: '/portal/system/marketing/getLocations',
    method: 'get',
    params: params
  });
}

// 分页查询
function marketingList(data) {
  return (0, _request.default)({
    url: '/portal/system/marketing/list',
    method: 'post',
    data: data
  });
}
// 保存
function marketingSave(data) {
  return (0, _request.default)({
    url: '/portal/system/marketing/save',
    method: 'post',
    data: data
  });
}
// 获取单个营销位信息
function marketingGetById(params) {
  return (0, _request.default)({
    url: '/portal/system/marketing/getById',
    method: 'get',
    params: params
  });
}
// 修改
function marketingUpdate(data) {
  return (0, _request.default)({
    url: '/portal/system/marketing/update',
    method: 'post',
    data: data
  });
}