"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PreviewPopup',
  data: function data() {
    return {
      dialogVisible: false,
      dataList: {}
    };
  },
  methods: {
    open: function open(data) {
      this.dialogVisible = true;
      this.dataList = data;
    },
    onClose: function onClose() {
      this.dialogVisible = false;
      this.dataList = [];
    }
  }
};