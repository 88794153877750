"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productAll = productAll;
exports.productDeleteById = productDeleteById;
exports.productGetGoodsDetailById = productGetGoodsDetailById;
exports.productList = productList;
exports.productOffShelf = productOffShelf;
exports.productRefGoods = productRefGoods;
exports.productSave = productSave;
exports.productUpdate = productUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
// 商品保存
function productSave(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/save',
    method: 'post',
    data: data
  });
}
// 商品删除
function productDeleteById(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/deleteById',
    method: 'post',
    data: data
  });
}
// 商品修改
function productUpdate(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/update',
    method: 'post',
    data: data
  });
}
// 商品列表
function productList(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/list',
    method: 'post',
    data: data
  });
}
// 商品下架
function productOffShelf(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/offShelf',
    method: 'post',
    data: data
  });
}
// 同步商品信息
function productRefGoods(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/refGoods',
    method: 'post',
    data: data
  });
}
// 所属活动
function productAll(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/activity/all',
    method: 'post',
    data: data
  });
}
// 查询商品详情
function productGetGoodsDetailById(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/goods/getGoodsDetailById',
    method: 'post',
    data: data
  });
}