"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listOrderPagedApi = listOrderPagedApi;
exports.loadOrderDetail = loadOrderDetail;
var _request = _interopRequireDefault(require("@/utils/request"));
function listOrderPagedApi(params) {
  return (0, _request.default)({
    url: '/portal/system/order/listOrderPaged',
    method: 'get',
    params: params
  });
}
function loadOrderDetail(params) {
  return (0, _request.default)({
    url: '/portal/system/order/loadOrderDetail',
    method: 'get',
    params: params
  });
}