"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _settings = _interopRequireDefault(require("@/settings"));
var title = _settings.default.title;
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(title, " - ").concat(pageTitle);
  }
  return "".concat(title);
}