var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "sublocalization" },
      [
        _c("el-tooltip", { attrs: { placement: "top" } }, [
          _vm.type === "loseEffectiveness"
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "bold" }, [_vm._v("失效按钮提示:")]),
                _vm._v("\n        1.失效状态不可解除，请谨慎操作！"),
                _c("br"),
                _vm._v(
                  "\n        2.失效后，该用户账号将即刻无法使用，强制退出登录；解绑关联微信；手机号码走重新注册流程；"
                ),
                _c("br"),
                _vm._v(
                  "\n        3.关系链保留：综合导购后台原有关系链保留，其他C端用户可以看到关系链上的失效邀请人；"
                ),
                _c("br"),
                _vm._v(
                  "\n        4.后台保留失效账号的部分信息，包括订单记录，交易记录等，支持通过昵称、ID搜索查询失效账号。"
                ),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "prohibit"
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "bold" }, [
                  _vm._v("禁止提现按钮提示:"),
                ]),
                _vm._v(
                  "\n        1.用户提现功能失效，请谨慎操作！其他功能保持正常(可解除禁止提现状态);"
                ),
                _c("br"),
                _vm._v(
                  "\n        2.用户的账户余额依然可以累积返利，但无法将现有资金提现第三方支付平台;"
                ),
                _c("br"),
                _vm._v(
                  "\n        3.用户进行提现操作时，出现账号锁定无法提现提示，需联系客服申诉处理;；"
                ),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "Blacklist"
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "bold" }, [_vm._v("拉黑按钮提示：")]),
                _vm._v(
                  "\n        1.拉黑后(可解除)，该用户账号将即刻无法使用，强制退出登录，请谨慎操作! 保留账号本身所有数据;"
                ),
                _c("br"),
                _vm._v(
                  "\n        2.用户再次登录时，出现账号异常提示，需联系客服申诉处理;"
                ),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "IssueDeduction"
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "bold" }, [
                  _vm._v("余额发放/扣除按钮提示:"),
                ]),
                _vm._v(
                  "\n        1.点击 “余额发放扣除” 后，系统将从用户账户的现有可提现余额中，扣除对应设定金额。扣除成功后，用户会即时收到站内通知，告知其账户余额发生变动。"
                ),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fc", staticStyle: { "margin-left": "4px" } },
            [
              _c("img", {
                staticStyle: { width: "12px", height: "12px" },
                attrs: {
                  src: require("@/assets/warning_reminder.png"),
                  alt: "",
                },
              }),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }