"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStatus = changeStatus;
exports.deleteLocation = deleteLocation;
exports.findAll = findAll;
exports.getConfig = getConfig;
exports.getConfigList = getConfigList;
exports.getList = getList;
exports.getLocations = getLocations;
exports.headerMsg = headerMsg;
exports.horseList = horseList;
exports.initBannerModel = initBannerModel;
exports.saveConfig = saveConfig;
exports.saveLocation = saveLocation;
exports.saveSetting = saveSetting;
exports.updateBannerModelStatus = updateBannerModelStatus;
exports.updateConfig = updateConfig;
exports.updateLocation = updateLocation;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList(params) {
  return (0, _request.default)({
    url: '/portal/system/tenant/listTenant',
    method: 'get',
    params: params
  });
}
function changeStatus(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/changeStatus',
    method: 'post',
    data: data
  });
}
function saveConfig(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/saveTenant',
    method: 'post',
    data: data
  });
}
function updateConfig(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/updateTenant',
    method: 'post',
    data: data
  });
}
function getConfig(params) {
  return (0, _request.default)({
    url: '/portal/system/tenant/getTenant',
    method: 'get',
    params: params
  });
}
function saveSetting(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/saveSetting',
    method: 'post',
    data: data
  });
}
function getConfigList(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/listSetting',
    method: 'post',
    data: data
  });
}
function findAll(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/findAll',
    method: 'post',
    data: data
  });
}
function initBannerModel(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/initBannerModel',
    method: 'post',
    data: data
  });
}
function getLocations(params) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/getLocations',
    method: 'get',
    params: params
  });
}
function saveLocation(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/saveLocation',
    method: 'post',
    data: data
  });
}
function deleteLocation(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/deleteLocation',
    method: 'post',
    data: data
  });
}
function updateLocation(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/updateLocation',
    method: 'post',
    data: data
  });
}
function updateBannerModelStatus(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/updateBannerModelStatus',
    method: 'post',
    data: data
  });
}
function headerMsg(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/list',
    method: 'post',
    data: data
  });
}
function horseList(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/list',
    method: 'post',
    data: data
  });
}