"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  props: {
    type: {
      // 类型（1:首页部分；2：个人中心部分）
      type: Number,
      default: 1
    },
    homeTopList: {
      type: Array,
      default: null
    },
    homeTopSecondList: {
      type: Array,
      default: null
    },
    homeMiddleList: {
      type: Array,
      default: null
    },
    homeFootList: {
      type: Array,
      default: null
    },
    tabBarList: {
      type: Array,
      default: null
    },
    centerList: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      homeTopData: {},
      homeTopSecondData: {},
      homeMiddleData: {},
      homeFootData: {},
      tabBarData: {},
      centerData: {},
      diamondTypeList: ['热门', '购物', '生活', '充值', '点单'],
      diamondList: [],
      typeList: ['淘宝', '天猫', '京东', '抖音', '唯品会'],
      textList: [{
        num: 1000,
        text: '今日预估'
      }, {
        num: 1000,
        text: '本月预估'
      }, {
        num: 1000,
        text: '本月收货'
      }, {
        num: 1000,
        text: '待结算'
      }],
      activeNum: 1,
      boole1: false,
      boole2: false,
      boole3: false,
      boole4: false,
      boole5: false
    };
  },
  computed: {},
  watch: {
    homeTopList: {
      // 模块1
      handler: function handler(newVal, oldVal) {
        var _this = this;
        // newVal是获取的是新的值
        newVal.map(function (i) {
          if (i.type && !i.text) {
            _this.homeTopData[i.type] = i.type === 'textColor' || i.type === 'bgColor' ? i.color : i.url;
          }
        });
        this.homeTopData['iconList'] = newVal.filter(function (i) {
          return i.text;
        });
        // console.log(this.homeTopData)
        this.$forceUpdate(); // 刷新视图
      },
      deep: true // deep属性是深度监听对象里的属性的值是否改变。
    },
    homeTopSecondList: {
      // 模块2
      handler: function handler(newVal) {
        var _this2 = this;
        newVal.map(function (i) {
          _this2.homeTopSecondData[i.type] = i.isShow;
        });
        this.$forceUpdate();
      },
      deep: true
    },
    homeMiddleList: {
      // 模块3
      handler: function handler(newVal) {
        var _this3 = this;
        newVal.map(function (i) {
          _this3.homeMiddleData[i.type] = i.isShow;
        });
        this.$forceUpdate();
      },
      deep: true
    },
    homeFootList: {
      // 模块4
      handler: function handler(newVal) {
        var _this4 = this;
        newVal.map(function (i) {
          _this4.homeFootData[i.type] = i.type === 'textColor' || i.type === 'tbBg' ? i.color : i.url;
        });
        this.$forceUpdate();
      },
      deep: true
    },
    tabBarList: {
      // 模块5
      handler: function handler(newVal) {
        this.tabBarData = newVal;
        this.$forceUpdate();
      },
      deep: true
    },
    centerList: {
      // 个人中心模块2
      handler: function handler(newVal) {
        var _this5 = this;
        // console.log(newVal)
        newVal.map(function (i) {
          if (i.bstop) {
            _this5.centerData[i.type] = i.color;
          }
        });
        this.centerData['iconList'] = newVal.filter(function (i) {
          return !i.bstop;
        });
        // console.log(this.centerData)
        this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    bothMouseOver: function bothMouseOver(val) {
      if (val === 1) {
        this.boole1 = true;
      } else if (val === 2) {
        this.boole2 = true;
      } else if (val === 3) {
        this.boole3 = true;
      } else if (val === 4) {
        this.boole4 = true;
      } else if (val === 5) {
        this.boole5 = true;
      }
    },
    bothMouseLeave: function bothMouseLeave(val) {
      if (val === 1) {
        this.boole1 = false;
      } else if (val === 2) {
        this.boole2 = false;
      } else if (val === 3) {
        this.boole3 = false;
      } else if (val === 4) {
        this.boole4 = false;
      } else if (val === 5) {
        this.boole5 = false;
      }
    },
    bothClick: function bothClick(val) {
      this.$emit('childData', val);
    }
  }
};