"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityList = activityList;
exports.deleteById = deleteById;
exports.save = save;
exports.upData = upData;
var _request = _interopRequireDefault(require("@/utils/request"));
// 活动保存
function save(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/activity/save',
    method: 'post',
    data: data
  });
}
// 活动删除
function deleteById(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/activity/deleteById',
    method: 'post',
    data: data
  });
}
// 活动修改
function upData(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/activity/update',
    method: 'post',
    data: data
  });
}

// 活动列表
function activityList(data) {
  return (0, _request.default)({
    url: '/portal/system/freepurchase/activity/list',
    method: 'post',
    data: data
  });
}