var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Information change" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "余额发放/扣除明细",
            visible: _vm.regionalInformation,
            "before-close": _vm.regionClose,
            "close-on-click-modal": false,
            width: "70%",
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.userOptionData, "max-height": "400" } },
            [
              _c("el-table-column", {
                attrs: {
                  property: "transTypeDesc",
                  label: "业务类型",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "incomeTypeDesc",
                  label: "账户类型",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "transAmount", label: "操作金额（元）" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "remark", label: "操作原因" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "operator", label: "操作人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "transTime", label: "操作时间" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pagination-container",
              staticStyle: { margin: "20px 0", "text-align": "right" },
            },
            [
              _c("el-pagination", {
                staticStyle: { display: "inline-block" },
                attrs: {
                  "current-page": _vm.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.limit,
                  total: _vm.total,
                  background: "",
                  layout: "total, prev, pager, next, sizes,jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }