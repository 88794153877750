var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customize_page_management" },
    [
      _c("div", { staticClass: "card frb" }, [
        _c("div", { staticClass: "flex" }, [_vm._v("自定义页面")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add_btn pointer", on: { click: _vm.onAdd } },
          [_vm._v("添加页面信息")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "请输入页面名称", clearable: "" },
              model: {
                value: _vm.queryData.title,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "title", $$v)
                },
                expression: "queryData.title",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.tableList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    width: "19%",
                    "margin-right": "1%",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "20px 10px" },
                        shadow: "hover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "20px",
                            color: "#333333",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            padding: "10px 0",
                            "border-bottom": "1px solid #E9E9E9",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "long-text",
                              staticStyle: {
                                "font-size": "14px",
                                color: "#333333",
                                "font-weight": "400",
                              },
                            },
                            [_vm._v("活动链接：" + _vm._s(item.openUrl))]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-document-copy",
                            staticStyle: {
                              color: "#1890FF",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(item.openUrl)
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.onEditor(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-edit",
                                staticStyle: {
                                  color: "#1890FF",
                                  "margin-right": "4px",
                                },
                              }),
                              _vm._v("编辑"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.onDelete(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: {
                                  color: "#FF0000",
                                  "margin-right": "4px",
                                },
                              }),
                              _vm._v("删除"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("el-empty", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableList.length === 0,
                expression: "tableList.length===0",
              },
            ],
            attrs: { "image-size": 200 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("customizePagePopup", {
        ref: "customizePagePopup",
        on: { refresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }