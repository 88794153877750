"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _creatApp = require("@/api/creatApp");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  data: function data() {
    return {
      topList: [{
        text: '已创建',
        key: 1
      }, {
        text: '已禁用',
        key: 2
      }],
      topTipNum: 1,
      appList: []
    };
  },
  computed: {},
  watch: {
    topTipNum: function topTipNum(val) {
      this.getAppList();
    }
  },
  mounted: function mounted() {
    this.getAppList();
  },
  methods: {
    detail: function detail(val) {
      console.log(val);
      this.$router.push({
        path: '/systemAdministrator/creatApp/appDetail',
        query: {
          id: "".concat(val.appId),
          name: "".concat(val.name)
        }
      });
    },
    getAppList: function getAppList() {
      var _this = this;
      (0, _creatApp.getList)({
        appStatus: this.topTipNum
      }).then(function (res) {
        if (_this.topTipNum === 1) res.data.push({
          id: 'add'
        });
        _this.appList = res.data;
      });
    },
    jumpAdd: function jumpAdd() {
      this.$router.push({
        path: '/systemAdministrator/creatApp/appInformation'
      });
    },
    del: function del(val) {
      var _this2 = this;
      this.$confirm(val.status === 1 ? '是否确认要禁用该APP?' : '是否确认要启用该APP?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(val);
        (0, _creatApp.changeStatus)({
          appId: val.appId,
          status: val.status === 1 ? 2 : 1
        }).then(function (res) {
          _this2.getAppList();
          _this2.$notify.success({
            title: '温馨提示',
            message: '操作成功'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};