var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("角色权限")]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入角色查询", clearable: "" },
              model: {
                value: _vm.queryData.roleName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "roleName", $$v)
                },
                expression: "queryData.roleName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入角色ID查询", clearable: "" },
              model: {
                value: _vm.queryData.id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "id", $$v)
                },
                expression: "queryData.id",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择角色状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "禁用" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "启用" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "角色ID", align: "center", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "角色名称", align: "center", prop: "roleName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  label: "角色描述",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          scope.row.status === 1
                            ? _c("div", { staticStyle: { color: "#67c23A" } }, [
                                _vm._v("\n              启用\n            "),
                              ])
                            : _c("div", { staticStyle: { color: "#F56C6C" } }, [
                                _vm._v("\n              禁用\n            "),
                              ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "fc" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warning" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onAccredit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("授权")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditor(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "" + (_vm.ControlsType ? "新增角色" : "角色编辑"),
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "roleName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "roleName", $$v)
                      },
                      expression: "ruleForm.roleName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色描述", prop: "description" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "description", $$v)
                      },
                      expression: "ruleForm.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.charactersDialogVisible,
            "close-on-click-modal": false,
            title: "角色授权",
            width: "850px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onCharactersClose },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.onUnfold },
                  model: {
                    value: _vm.checked1,
                    callback: function ($$v) {
                      _vm.checked1 = $$v
                    },
                    expression: "checked1",
                  },
                },
                [_vm._v("全部展开")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.allTreeData,
              "show-checkbox": "",
              "render-after-expand": false,
              "node-key": "id",
              "default-expanded-keys": [],
              "default-checked-keys": _vm.checkedKeys,
              "default-expand-all": _vm.checked1,
              props: _vm.defaultProps,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onVerifyAccredit },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onResetAccredit } }, [
                _vm._v("重置"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }