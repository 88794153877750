var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("APP人员管理")]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户ID查询", clearable: "" },
              model: {
                value: _vm.queryData.uid,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "uid", $$v)
                },
                expression: "queryData.uid",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户昵称查询", clearable: "" },
              model: {
                value: _vm.queryData.nickName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "nickName", $$v)
                },
                expression: "queryData.nickName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入手机号查询", clearable: "" },
              model: {
                value: _vm.queryData.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "phone", $$v)
                },
                expression: "queryData.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "所属APP",
                },
                model: {
                  value: _vm.queryData.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "appId", $$v)
                  },
                  expression: "queryData.appId",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.appId,
                  attrs: { label: item.name, value: item.appId },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "用户ID", align: "center", prop: "uid" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户昵称", align: "center", prop: "nickName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号码", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "roles",
                  label: "所属APP及角色",
                  align: "center",
                  width: "600",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.appRoleMappings
                          ? [
                              scope.row.appRoleMappings
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "fc",
                                      staticStyle: { "font-size": "12px" },
                                    },
                                    _vm._l(
                                      scope.row.appRoleMappings,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              border: "1px solid #C6C6C6",
                                              padding: "10px",
                                              "border-radius": "4px",
                                              margin: "0px 5px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "#333333",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.appName))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex",
                                                staticStyle: {
                                                  "flex-wrap": "wrap",
                                                },
                                              },
                                              _vm._l(
                                                item.roleAbbreviations,
                                                function (i, k) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: k,
                                                      staticClass: "fc",
                                                      staticStyle: {
                                                        color: "#666666",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(i.roleName) +
                                                          "\n                  "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item
                                                                  .roleAbbreviations
                                                                  .length -
                                                                  1 !==
                                                                k,
                                                              expression:
                                                                "item.roleAbbreviations.length-1 !== k",
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            margin: "0px 6px",
                                                          },
                                                        },
                                                        [_vm._v("|")]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c("div", { staticStyle: { color: "#67c23A" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 1
                          ? _c("div", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "fc" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditor(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "APP人员" + (_vm.ControlsType ? "新增" : "编辑"),
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户昵称", prop: "nickName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "nickName", $$v)
                      },
                      expression: "ruleForm.nickName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "phone", $$v)
                      },
                      expression: "ruleForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.ruleForm.mappings, function (item, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: "" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "border-radius": "10px",
                          background: "#eee",
                          padding: "10px 20px 20px 20px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "frb", staticStyle: { color: "red" } },
                          [
                            _c("div"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelMap(index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择App" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onAppChange($event, index)
                                  },
                                },
                                model: {
                                  value: item.appId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "appId", $$v)
                                  },
                                  expression: "item.appId",
                                },
                              },
                              _vm._l(_vm.appAndRolesData, function (k) {
                                return _c("el-option", {
                                  key: k.appId,
                                  attrs: { label: k.appName, value: k.appId },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: { label: "角色" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: item.roleIds,
                                  callback: function ($$v) {
                                    _vm.$set(item, "roleIds", $$v)
                                  },
                                  expression: "item.roleIds",
                                },
                              },
                              _vm._l(item.options, function (i) {
                                return _c("el-option", {
                                  key: i.id,
                                  attrs: { label: i.roleName, value: i.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.onAddItem } }, [
                    _vm._v("新增"),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }