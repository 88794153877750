var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CustomActivity_box" },
    [
      _c("div", { staticClass: "search_criteria mt20" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "请输入活动名称", clearable: "" },
              model: {
                value: _vm.queryData.name,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "name", $$v)
                },
                expression: "queryData.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "活动状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "已结束" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "平台类型", clearable: "" },
                model: {
                  value: _vm.queryData.unionCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "unionCode", $$v)
                  },
                  expression: "queryData.unionCode",
                },
              },
              [
                _c("el-option", { attrs: { label: "淘宝", value: "TB" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "京东", value: "JD" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "抖音", value: "DY" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "拼多多", value: "PDD" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "唯品会", value: "WPH" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onRest },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticClass: " flex fw ",
          staticStyle: {
            display: "flex",
            "justify-content": "flex-start",
            "flex-wrap": "wrap",
          },
        },
        _vm._l(_vm.dataList, function (item) {
          return _c(
            "ItemActivity",
            {
              key: item.activityId,
              staticStyle: {
                width: "19%",
                "margin-right": "1%",
                "margin-top": "20px",
              },
              attrs: { item: item },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-around",
                    margin: "15px 0",
                  },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.onEditor(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-edit",
                        staticStyle: {
                          color: "#1890FF",
                          "margin-right": "4px",
                        },
                      }),
                      _vm._v("编辑"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-delete",
                        staticStyle: {
                          color: "#FF0000",
                          "margin-right": "4px",
                        },
                      }),
                      _vm._v("删除"),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("el-empty", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataList.length === 0,
            expression: "dataList.length===0",
          },
        ],
        attrs: { "image-size": 200 },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("CustomActivityPopup", {
        ref: "customActivityPopup",
        on: { refreshEditor: _vm.refreshEditor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }