var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("淘礼金创建记录")]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入商品ID查询", clearable: "" },
              model: {
                value: _vm.queryData.goodsId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "goodsId", $$v)
                },
                expression: "queryData.goodsId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户ID查询", clearable: "" },
              model: {
                value: _vm.queryData.memberId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "memberId", $$v)
                },
                expression: "queryData.memberId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入礼金ID查询", clearable: "" },
              model: {
                value: _vm.queryData.id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "id", $$v)
                },
                expression: "queryData.id",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户昵称查询", clearable: "" },
              model: {
                value: _vm.queryData.nickname,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "nickname", $$v)
                },
                expression: "queryData.nickname",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "待生效" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 3, label: "创建失败" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 4, label: "已过期" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "pull_down_box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "ml10 fc",
                  staticStyle: { height: "40px", width: "70px" },
                  attrs: { type: "info", plain: "" },
                },
                [
                  _c("img", {
                    staticStyle: { width: "16px", height: "16px" },
                    attrs: {
                      src: require("@/assets/member/UserManage/icon1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: {
                      width: "12px",
                      height: "12px",
                      "margin-left": "10px",
                    },
                    attrs: {
                      src: require("@/assets/member/UserManage/icon2.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "down_item" }, [
                _c(
                  "div",
                  { staticClass: "style:height:100%" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.onCheckBox },
                        model: {
                          value: _vm.checkList,
                          callback: function ($$v) {
                            _vm.checkList = $$v
                          },
                          expression: "checkList",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "商品图片" } },
                              [_vm._v("商品图片")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c("el-checkbox", { attrs: { label: "会员" } }, [
                              _vm._v("会员"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "商品名称" } },
                              [_vm._v("商品名称")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c("el-checkbox", { attrs: { label: "商品ID" } }, [
                              _vm._v("商品ID"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "单个礼金金额（元）" } },
                              [_vm._v("单个礼金金额（元）")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "发放个数" } },
                              [_vm._v("发放个数")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "已领取使用（个）" } },
                              [_vm._v("已领取使用（个）")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "已核销金额（元）" } },
                              [_vm._v("已核销金额（元）")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "淘礼金状态" } },
                              [_vm._v("淘礼金状态")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "创建时间" } },
                              [_vm._v("创建时间")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "领取时间" } },
                              [_vm._v("领取时间")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "使用时间" } },
                              [_vm._v("使用时间")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "tooltip-effect": "dark",
              },
            },
            [
              _vm.checkList.includes("商品图片")
                ? _c("el-table-column", {
                    attrs: { prop: "", label: "商品图片", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "80px",
                                  },
                                  attrs: {
                                    src: scope.row.goodsDetailVo.picUrl,
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2803442591
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "会员", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "pointer",
                            staticStyle: {
                              "font-size": "14px",
                              color: "#4d8aff",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onUserDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.nickName) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("uid:" + _vm._s(scope.row.memberId)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.checkList.includes("商品名称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "alipayAccountNo",
                      label: "商品名称",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.goodsDetailVo.title) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1932675815
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("商品ID")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "goodsId",
                      label: "商品ID",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "礼金ID", align: "center" },
              }),
              _vm._v(" "),
              _vm.checkList.includes("单个礼金金额（元）")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "perFace",
                      label: "单个礼金金额（元）",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("发放个数")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      label: "发放个数",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("已核销金额（元）")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "settleAmt",
                      label: "已核销金额（元）",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("淘礼金状态")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "淘礼金状态",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                scope.row.status === 1
                                  ? _c("div", [_vm._v("进行中")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 2
                                  ? _c("div", [_vm._v("待生效")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 3
                                  ? _c("div", [_vm._v("创建失败")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 4
                                  ? _c("div", [_vm._v("已过期")])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      691453807
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("创建时间")
                ? _c("el-table-column", {
                    attrs: {
                      width: "100",
                      prop: "createTime",
                      label: "创建时间",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("领取时间")
                ? _c("el-table-column", {
                    attrs: { label: "领取时间", align: "center", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.sendStartTime) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.sendEndTime) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3413444767
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("使用时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "sendStartTime",
                      label: "使用时间",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.useEndTimeMode === "2"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.useStartTime) +
                                          "\n            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.useEndTime) +
                                          "\n            "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.useEndTimeMode === "1"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.useEndTime) + "天"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1322109414
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  label: "操作",
                  width: "180",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.titleTxt,
            width: "800px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ml10",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex mt10",
                          staticStyle: {
                            border: "1px solid #dcdfe6",
                            width: "fit-content",
                            "border-radius": "10px",
                            padding: "10px",
                            "line-height": "20px",
                          },
                        },
                        [
                          _c("div", { staticClass: "fc" }, [
                            _c("img", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/copy_icon.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml10" }, [
                            _c("div", [_vm._v("用户昵称")]),
                            _vm._v(" "),
                            _c("div", [_vm._v("UID:13461360124")]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "充值金额", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注原因", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }