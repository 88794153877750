"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listBalance = listBalance;
exports.queryMemberUpdateLogsApi = queryMemberUpdateLogsApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 用户信息变更记录列表
function queryMemberUpdateLogsApi(params) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberUpdateLogs',
    method: 'get',
    params: params
  });
}
function listBalance(params) {
  return (0, _request.default)({
    url: '/portal/system/account/listBalance',
    method: 'get',
    params: params
  });
}