var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            "body-style": { padding: "0", "border-radius": "20px" },
            shadow: "hover",
          },
        },
        [
          _c(
            "div",
            [
              _c("el-image", {
                staticStyle: {
                  width: "100%",
                  height: "140px",
                  display: "block",
                },
                attrs: {
                  src: _vm.item.headerPic,
                  fit: "fill",
                  "preview-src-list": [_vm.item.headerPic],
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px",
                "border-bottom": "1px solid #E9E9E9",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "long-text",
                      staticStyle: {
                        "font-size": "16px",
                        color: "rgba(0,0,0,0.85)",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.item.activityName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "round-box",
                        style: { background: _vm.colorObj[_vm.item.status] },
                      }),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(_vm.textObj[_vm.item.status])),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "startTime flex" }, [
                _vm._v("\n        用户类型：\n        "),
                _vm.item.targetUserType === 3
                  ? _c("div", [_vm._v("所有用户")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.targetUserType === 1
                  ? _c("div", [_vm._v("未出单")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.targetUserType === 2
                  ? _c("div", [_vm._v("90天未出单")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "startTime" }, [
                _vm._v("创建时间：----"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "startTime" }, [
                _vm._v("开始时间：" + _vm._s(_vm.item.activityStartTime)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "startTime" }, [
                _vm._v("结束时间：" + _vm._s(_vm.item.activityEndTime)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c("div", { staticClass: "startTime long-text" }, [
                    _vm._v("活动链接：" + _vm._s(_vm.item.openUrl)),
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-document-copy",
                    staticStyle: { color: "#1890FF", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.copy(_vm.item.openUrl)
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-around",
                margin: "15px 0",
              },
            },
            [
              _c(
                "el-link",
                {
                  attrs: { underline: false },
                  on: {
                    click: function ($event) {
                      return _vm.onControls("editor")
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-edit",
                    staticStyle: { color: "#1890FF", "margin-right": "4px" },
                  }),
                  _vm._v("编辑"),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-link",
                {
                  attrs: { underline: false },
                  on: {
                    click: function ($event) {
                      return _vm.onControls("delete")
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-delete",
                    staticStyle: { color: "#FF0000", "margin-right": "4px" },
                  }),
                  _vm._v("删除"),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }