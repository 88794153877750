var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("人员管理")]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户ID查询", clearable: "" },
              model: {
                value: _vm.queryData.uid,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "uid", $$v)
                },
                expression: "queryData.uid",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户昵称查询", clearable: "" },
              model: {
                value: _vm.queryData.nickName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "nickName", $$v)
                },
                expression: "queryData.nickName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入手机号查询", clearable: "" },
              model: {
                value: _vm.queryData.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "phone", $$v)
                },
                expression: "queryData.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: { clearable: "", placeholder: "请选择角色" },
                model: {
                  value: _vm.queryData.roleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "roleId", $$v)
                  },
                  expression: "queryData.roleId",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.roleName, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "用户ID", align: "center", prop: "uid" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户昵称", align: "center", prop: "nickName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "用户手机号", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "roles", label: "用户角色", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.roles, function (item) {
                        return _c("div", { key: item }, [_vm._v(_vm._s(item))])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c("div", { staticStyle: { color: "#67c23A" } }, [
                              _vm._v("启用"),
                            ])
                          : _c("div", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("禁用"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lastLoginTime",
                  label: "最近登录时间",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isDel
                          ? _c(
                              "div",
                              { staticClass: "fc" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEditor(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDel(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: (_vm.ControlsType ? "添加" : "编辑") + "用户",
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户昵称", prop: "nickname" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "nickname", $$v)
                      },
                      expression: "ruleForm.nickname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isShowRole },
                    model: {
                      value: _vm.ruleForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "phone", $$v)
                      },
                      expression: "ruleForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showRole !== 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属角色", prop: "appRoleMappings" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isShowRole,
                            multiple: "",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.ruleForm.appRoleMappings,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "appRoleMappings", $$v)
                            },
                            expression: "ruleForm.appRoleMappings",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.roleName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }