var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Information change" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户信息变动",
            visible: _vm.regionalInformation,
            "before-close": _vm.regionClose,
            "close-on-click-modal": false,
            width: "70%",
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.userOptionData, "max-height": "400" } },
            [
              _c("el-table-column", {
                attrs: {
                  property: "memberId",
                  label: "被操作用户ID",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "operator",
                  label: "操作人",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "originValue",
                  label: "修改前的值",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "targetValue",
                  label: "修改后的值",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "createTime",
                  label: "修改时间",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "operationType",
                  "min-width": "150",
                  label: "操作类型",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.logType.filter(function (i) {
                                return i.type * 1 === scope.row.type * 1
                              }).length > 0
                                ? _vm.logType.filter(function (i) {
                                    return i.type * 1 === scope.row.type * 1
                                  })[0].name
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "remark",
                  "min-width": "200",
                  label: "操作原因",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }