"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteActivityApi = deleteActivityApi;
exports.getActivityApi = getActivityApi;
exports.listActivityApi = listActivityApi;
exports.saveActivityApi = saveActivityApi;
exports.updateActivityApi = updateActivityApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 通用活动创建

// 查询活动列表
function listActivityApi(data) {
  return (0, _request.default)({
    url: '/portal/system/activity/listActivity',
    method: 'post',
    data: data
  });
}
// 添加活动
function saveActivityApi(data) {
  return (0, _request.default)({
    url: '/portal/system/activity/saveActivity',
    method: 'post',
    data: data
  });
}

// 编辑活动
function updateActivityApi(data) {
  return (0, _request.default)({
    url: '/portal/system/activity/updateActivity',
    method: 'post',
    data: data
  });
}

// 删除活动
function deleteActivityApi(data) {
  return (0, _request.default)({
    url: '/portal/system/activity/deleteActivity',
    method: 'post',
    data: data
  });
}
// 查询活动
function getActivityApi(params) {
  return (0, _request.default)({
    url: '/portal/system/activity/getActivity',
    method: 'get',
    params: params
  });
}