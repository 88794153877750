"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItem = addItem;
exports.delGroup = delGroup;
exports.delItem = delItem;
exports.dragUpdateConfig = dragUpdateConfig;
exports.findConfigAll = findConfigAll;
exports.findConfigGroupList = findConfigGroupList;
exports.getList = getList;
exports.saveGroup = saveGroup;
exports.updateGroup = updateGroup;
exports.updateItem = updateItem;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/findConfigList',
    method: 'post',
    data: params
  });
}
function findConfigAll(query) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/findConfigAll',
    method: 'get',
    params: query
  });
}
function addItem(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/save',
    method: 'post',
    data: params
  });
}
function delItem(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/deleteById',
    method: 'post',
    data: params
  });
}
function updateItem(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/update',
    method: 'post',
    data: params
  });
}
function dragUpdateConfig(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/dragUpdateConfig',
    method: 'post',
    data: params
  });
}
function findConfigGroupList(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/group/list',
    method: 'post',
    data: params
  });
}
function saveGroup(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/group/save',
    method: 'post',
    data: params
  });
}
function updateGroup(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/group/update',
    method: 'post',
    data: params
  });
}
function delGroup(params) {
  return (0, _request.default)({
    url: '/portal/system/personalCenter/group/delete',
    method: 'post',
    data: params
  });
}