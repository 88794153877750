var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "card frb" }, [
        _c("div", { staticClass: "flex" }, [_vm._v("\n      聚合活动\n    ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add_btn pointer", on: { click: _vm.onAdd } },
          [_vm._v("添加活动")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "search_criteria",
          staticStyle: { "margin-left": "10px" },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入活动名称", clearable: "" },
                model: {
                  value: _vm.queryData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "name", $$v)
                  },
                  expression: "queryData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { width: "fit-content" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.onInitialize },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: " flex fw ",
          staticStyle: {
            display: "flex",
            "justify-content": "flex-start",
            "flex-wrap": "wrap",
          },
        },
        _vm._l(_vm.dataList, function (item) {
          return _c(
            "ItemActivity",
            {
              key: item.activityId,
              staticStyle: {
                width: "19%",
                "margin-left": "1%",
                "margin-top": "20px",
              },
              attrs: { item: item },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-around",
                    margin: "15px 0",
                  },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.onPreview(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-view",
                        staticStyle: {
                          color: "#000000",
                          "margin-right": "4px",
                        },
                      }),
                      _vm._v("预览"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.onEditor(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-edit",
                        staticStyle: {
                          color: "#1890FF",
                          "margin-right": "4px",
                        },
                      }),
                      _vm._v("编辑"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-delete",
                        staticStyle: {
                          color: "#FF0000",
                          "margin-right": "4px",
                        },
                      }),
                      _vm._v("删除"),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("el-empty", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataList.length === 0,
            expression: "dataList.length===0",
          },
        ],
        attrs: { "image-size": 200 },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("CustomActivityPopup", {
        ref: "customActivityPopup",
        on: { refresh: _vm.onRefresh },
      }),
      _vm._v(" "),
      _c("previewPopup", { ref: "previewPopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }