var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fc" }, [
    _c(
      "div",
      { staticClass: "configuration" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c("el-form-item", { attrs: { label: "提现申请", prop: "name" } }, [
              _c(
                "div",
                { staticClass: "ml20" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.ruleForm.withdrawSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawSwitch", $$v)
                        },
                        expression: "ruleForm.withdrawSwitch",
                      },
                    },
                    [_vm._v("开启")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.ruleForm.withdrawSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawSwitch", $$v)
                        },
                        expression: "ruleForm.withdrawSwitch",
                      },
                    },
                    [_vm._v("不开启")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.ruleForm.withdrawSwitch,
                    expression: "!ruleForm.withdrawSwitch",
                  },
                ],
                attrs: { label: "关闭提醒", prop: "tips" },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.ruleForm.tips,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "tips", $$v)
                    },
                    expression: "ruleForm.tips",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "月结账单日", prop: "billStatsDay" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.ruleForm.billStatsDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "billStatsDay", $$v)
                      },
                      expression: "ruleForm.billStatsDay",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [_vm._v("每月")]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("日")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "次数限制", prop: "withdrawCountAccount" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.withdrawCountAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "withdrawCountAccount", $$v)
                      },
                      expression: "ruleForm.withdrawCountAccount",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("同一个支付宝提现账户每天最多申请提现次数"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("次")]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", [_vm._v("0 不限制，失败不计数")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "withdrawCountUser" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.withdrawCountUser,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "withdrawCountUser", $$v)
                      },
                      expression: "ruleForm.withdrawCountUser",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("同一个账号每天最多申请提现次数"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("次")]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", [_vm._v("0 不限制，失败不计数")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "自购账户限制", prop: "amountLimit" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.amountLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amountLimit", $$v)
                      },
                      expression: "ruleForm.amountLimit",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("单笔最低提现金额"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("元")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "amountMultipleLimit" } },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "mt10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.amountMultipleLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amountMultipleLimit", $$v)
                      },
                      expression: "ruleForm.amountMultipleLimit",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("单笔提现金额倍数限制"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("的倍数")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "团队账户提现限制", prop: "amountLimitTeam" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.amountLimitTeam,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amountLimitTeam", $$v)
                      },
                      expression: "ruleForm.amountLimitTeam",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("单笔最低提现金额"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("元")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "amountMultipleLimitTeam" } },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "mt10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.amountMultipleLimitTeam,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amountMultipleLimitTeam", $$v)
                      },
                      expression: "ruleForm.amountMultipleLimitTeam",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("单笔提现金额倍数限制"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("的倍数")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "feeAmountTeam" } },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "mt10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.feeAmountTeam,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "feeAmountTeam", $$v)
                      },
                      expression: "ruleForm.feeAmountTeam",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [_vm._v("提现手续费")]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("元")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "实名认证金额限制", prop: "realNameAmount" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入X值" },
                    model: {
                      value: _vm.ruleForm.realNameAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "realNameAmount", $$v)
                      },
                      expression: "ruleForm.realNameAmount",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("当提现金额大于X元时，触发实名认证"),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "append" }, [_vm._v("元")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "自动打款", prop: "autoGive" } },
              [
                _c(
                  "div",
                  { staticClass: "ml20" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: true },
                        model: {
                          value: _vm.ruleForm.autoGive,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "autoGive", $$v)
                          },
                          expression: "ruleForm.autoGive",
                        },
                      },
                      [_vm._v("开启")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: false },
                        model: {
                          value: _vm.ruleForm.autoGive,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "autoGive", $$v)
                          },
                          expression: "ruleForm.autoGive",
                        },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ruleForm.autoGive,
                        expression: "ruleForm.autoGive",
                      },
                    ],
                    staticClass: "mt10 configuration_in_the",
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "autoGiveFirstLimit" } },
                      [
                        _c("div", { staticClass: "flex payment" }, [
                          _c("div", { staticClass: "disposition_text" }, [
                            _vm._v("1: 首次提现不自动打款"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "ml10" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.ruleForm.autoGiveFirstLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "autoGiveFirstLimit",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.autoGiveFirstLimit",
                                  },
                                },
                                [_vm._v("开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.ruleForm.autoGiveFirstLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "autoGiveFirstLimit",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.autoGiveFirstLimit",
                                  },
                                },
                                [_vm._v("不开启")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "autoGiveTimeLimit" } },
                      [
                        _c("div", { staticClass: "flex payment" }, [
                          _c("div", { staticClass: "disposition_text" }, [
                            _vm._v("2: 自动打款时间段限制"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "ml10" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.ruleForm.autoGiveTimeLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "autoGiveTimeLimit",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.autoGiveTimeLimit",
                                  },
                                },
                                [_vm._v("开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.ruleForm.autoGiveTimeLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "autoGiveTimeLimit",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.autoGiveTimeLimit",
                                  },
                                },
                                [_vm._v("不开启")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.ruleForm.autoGiveTimeLimit
                      ? _c("div", { staticClass: "flex payment" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "startTime" } },
                                [
                                  _c("el-time-select", {
                                    attrs: {
                                      "picker-options": {
                                        start: "00:00",
                                        step: "01:00",
                                        end: "23:00",
                                      },
                                      placeholder: "选择时间",
                                    },
                                    model: {
                                      value: _vm.ruleForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                                      },
                                      expression: "ruleForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml10",
                              staticStyle: { width: "180px" },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "endTime" } },
                                [
                                  _c("el-time-select", {
                                    attrs: {
                                      "picker-options": {
                                        start: "00:00",
                                        step: "01:00",
                                        end: "23:00",
                                      },
                                      placeholder: "选择时间",
                                    },
                                    model: {
                                      value: _vm.ruleForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                                      },
                                      expression: "ruleForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mt10",
                        staticStyle: { color: "#FA8C62" },
                      },
                      [_vm._v("须同时满足以下设置条件，才能成功自动打款")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "autoGiveAmountLimit" } },
                      [
                        _c("div", { staticClass: "payment " }, [
                          _c("div", { staticClass: "disposition_text" }, [
                            _vm._v("3：提现金额大于X（元）不自动打款"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: " flex",
                              staticStyle: { width: "180px" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: " 请输入具体金额" },
                                model: {
                                  value: _vm.ruleForm.autoGiveAmountLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "autoGiveAmountLimit",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.autoGiveAmountLimit",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "payment " }, [
                      _c("div", { staticClass: "disposition_text" }, [
                        _vm._v(
                          "\n              4：60天内商品佣金比例大于 x%，占总订单数量比例大于y% ，不自动打款\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: " flex" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt10",
                              attrs: { label: "", prop: "commissionX" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "180px" },
                                attrs: { placeholder: "请输入具体比例（x值）" },
                                model: {
                                  value: _vm.ruleForm.commissionX,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "commissionX", $$v)
                                  },
                                  expression: "ruleForm.commissionX",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt10",
                              attrs: { label: "", prop: "commissionY" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "ml10",
                                staticStyle: { width: "180px" },
                                attrs: { placeholder: "请输入具体比例（y值）" },
                                model: {
                                  value: _vm.ruleForm.commissionY,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "commissionY", $$v)
                                  },
                                  expression: "ruleForm.commissionY",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt10",
                        attrs: { label: "", prop: "expireX" },
                      },
                      [
                        _c("div", { staticClass: "payment" }, [
                          _c("div", { staticClass: "disposition_text" }, [
                            _vm._v(
                              "\n                5：近60天内失效订单占比大于x% 不自动打款\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: " flex",
                              staticStyle: { width: "180px" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入具体比例（x值）" },
                                model: {
                                  value: _vm.ruleForm.expireX,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "expireX", $$v)
                                  },
                                  expression: "ruleForm.expireX",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt10",
                        attrs: { label: "", prop: "withdrawX" },
                      },
                      [
                        _c("div", { staticClass: "payment" }, [
                          _c("div", { staticClass: "disposition_text" }, [
                            _vm._v(
                              "\n                6：30天内提现金额，大于60天确认收货佣金x%，不自动打款\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: " flex",
                              staticStyle: { width: "180px" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入具体比例（x值）" },
                                model: {
                                  value: _vm.ruleForm.withdrawX,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "withdrawX", $$v)
                                  },
                                  expression: "ruleForm.withdrawX",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.resetForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }