var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CustomOfficialEvents" }, [
    _c("div", { staticClass: "mt10 frb" }, [
      _c("div"),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "活动名称", clearable: "" },
              model: {
                value: _vm.queryData.activityName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "activityName", $$v)
                },
                expression: "queryData.activityName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择活动状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "已结束" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "mt10",
          staticStyle: { "overflow-y": "auto", width: "100%", height: "600px" },
        },
        [
          _c(
            "div",
            { staticClass: "flex fw" },
            _vm._l(_vm.tableData, function (item, index) {
              return _c("div", { key: index, staticClass: "details_box" }, [
                _c("div", { staticClass: "details_img" }, [
                  item.headerPic
                    ? _c("img", { attrs: { src: item.headerPic, alt: "" } })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                          alt: "",
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details_txt" }, [
                  _c("div", { staticClass: "txt_title ellipsis-multiline" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(item.activityName) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "startTime flex" }, [
                    _vm._v("\n              用户类型：\n              "),
                    item.targetUserType === 1
                      ? _c("div", [_vm._v("所有用户")])
                      : _vm._e(),
                    _vm._v(" "),
                    item.targetUserType === 2
                      ? _c("div", [_vm._v("未出单")])
                      : _vm._e(),
                    _vm._v(" "),
                    item.targetUserType === 3
                      ? _c("div", [_vm._v("90天未出单")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "startTime" }, [
                    _vm._v(
                      "\n              开始时间：" +
                        _vm._s(item.activityStartTime) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "startTime" }, [
                    _vm._v("结束时间：" + _vm._s(item.activityEndTime)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "frb mt4" }, [
                    _c("div", { staticClass: "fc" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "round-box",
                            style: { background: _vm.colorObj[item.status] },
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.textObj[item.status]) +
                                "\n                  "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "details_select pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onSelectEvents(item)
                          },
                        },
                      },
                      [_vm._v("\n                √选择\n              ")]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 page_box" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryData.page.page,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.queryData.page.size,
                  layout: "total, prev, pager, next, sizes,jumper",
                  total: _vm.pagination.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }