"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminAddOrUpdateAppUser = adminAddOrUpdateAppUser;
exports.queryAppAndRoles = queryAppAndRoles;
exports.queryAppManageRolesByAppId = queryAppManageRolesByAppId;
exports.queryAppManageRolesByAppIdAndUserId = queryAppManageRolesByAppIdAndUserId;
exports.queryAppUserInAdminPage = queryAppUserInAdminPage;
exports.queryRolesByAppld = queryRolesByAppld;
var _request = _interopRequireDefault(require("@/utils/request"));
// [new]超管端App人员列表
function queryAppUserInAdminPage(data) {
  return (0, _request.default)({
    url: '/portal/system/user/queryAppUserInAdminPage',
    method: 'post',
    data: data
  });
}
// [new]超管端新增或编辑App人员
function adminAddOrUpdateAppUser(data) {
  return (0, _request.default)({
    url: '/portal/system/user/adminAddOrUpdateAppUser',
    method: 'post',
    data: data
  });
}

// 根据app获取应用角色/portal/system/role/queryRolesByAppld
function queryRolesByAppld(data) {
  return (0, _request.default)({
    url: '/portal/system/role/queryRolesByAppld',
    method: 'post',
    data: data
  });
}
// ]获取应用和对应的角色
function queryAppAndRoles(params) {
  return (0, _request.default)({
    url: '/portal/system/role/queryAppAndRoles',
    method: 'get',
    params: params
  });
}

// [new]app人员管理根据appId和userId获取角色列表回显
function queryAppManageRolesByAppIdAndUserId(data) {
  return (0, _request.default)({
    url: '/portal/system/role/queryAppManageRolesByAppIdAndUserId',
    method: 'post',
    data: data
  });
}

// [new]app人员管理根据appId获取角色列表
function queryAppManageRolesByAppId(params) {
  return (0, _request.default)({
    url: '/portal/system/role/queryAppManageRolesByAppId',
    method: 'get',
    params: params
  });
}