var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appInformation_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("APP信息")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content_box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "APP名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "APP简介" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.abbreviation,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "abbreviation", $$v)
                      },
                      expression: "ruleForm.abbreviation",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "默认管理员", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.changeRadio },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("添加管理员"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("选择已有人员"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.type === 0
                ? _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#D7D7D7",
                          "border-radius": "10px",
                          padding: "20px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "管理员昵称",
                              prop: "defaultUser.nickname",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.defaultUser.nickname,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.defaultUser,
                                    "nickname",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.defaultUser.nickname",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              label: "手机号码",
                              prop: "defaultUser.phone",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.defaultUser.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.defaultUser,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.defaultUser.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              label: "管理员状态",
                              prop: "defaultUser.status",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.ruleForm.defaultUser.status,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.defaultUser,
                                      "status",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.defaultUser.status",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("禁用"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-top": "10px", color: "red" },
                          },
                          [
                            _vm._v(
                              "注：管理员添加后，将同步至APP人员管理内，默认管理员不可删除"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.type === 1
                ? _c("el-form-item", { attrs: { label: "选择人员" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        staticStyle: { color: "#409EFF" },
                        on: { click: _vm.onGoToUser },
                      },
                      [_vm._v("\n          去选择\n        ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.type === 1 && _vm.ruleForm.uid
                ? _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#D7D7D7",
                          "border-radius": "10px",
                          padding: "20px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "管理员昵称",
                              prop: "defaultUser.nickname",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.defaultUser.nickname,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.defaultUser,
                                    "nickname",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.defaultUser.nickname",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              label: "手机号码",
                              prop: "defaultUser.phone",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.defaultUser.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.defaultUser,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.defaultUser.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              label: "管理员状态",
                              prop: "defaultUser.status",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.ruleForm.defaultUser.status,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.defaultUser,
                                      "status",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.defaultUser.status",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("禁用"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-top": "10px", color: "red" },
                          },
                          [
                            _vm._v(
                              "注：管理员添加后，将同步至APP人员管理内，默认管理员不可删除"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "APP图标", prop: "icon" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "",
                        "http-request": function (value) {
                          return _vm.handleFileUpload(value, "icon")
                        },
                        "on-remove": function (value) {
                          return _vm.handleRemove(value, "icon")
                        },
                        "list-type": "picture-card",
                        limit: 1,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#969696" } }, [
                    _vm._v(
                      "180*180PNG（图标应用的位置：登录页、首页底部登录条、发圈）"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.drawer,
            direction: _vm.direction,
            "with-header": "",
            "before-close": _vm.handleClose,
            size: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { padding: "0 30px" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入用户昵称查询", clearable: "" },
                model: {
                  value: _vm.queryData.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "nickName", $$v)
                  },
                  expression: "queryData.nickName",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { "margin-left": "10px" },
                attrs: { placeholder: "输入用户手机号查询", clearable: "" },
                model: {
                  value: _vm.queryData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "phone", $$v)
                  },
                  expression: "queryData.phone",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { height: "40px", "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onInquire },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  staticStyle: { height: "40px" },
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.onInitialize },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                padding: "0 20px 50px",
                overflow: "auto",
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    height: "700",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户ID", align: "center", prop: "id" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户昵称",
                      align: "center",
                      prop: "nickName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "手机号", align: "center", prop: "phone" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelect(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }