"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteBusinessCollege = deleteBusinessCollege;
exports.getBusinessCollege = getBusinessCollege;
exports.listBusinessCollege = listBusinessCollege;
exports.saveBusinessCollege = saveBusinessCollege;
exports.updateBusinessCollege = updateBusinessCollege;
var _request = _interopRequireDefault(require("@/utils/request"));
// 刪除商学院
function deleteBusinessCollege(data) {
  return (0, _request.default)({
    url: '/portal/system/businessCollege/deleteBusinessCollege',
    method: 'post',
    data: data
  });
}
// 查询商学院
function getBusinessCollege(params) {
  return (0, _request.default)({
    url: '/portal/system/businessCollege/getBusinessCollege',
    method: 'get',
    params: params
  });
}

// 查询商学院列表
function listBusinessCollege(data) {
  return (0, _request.default)({
    url: '/portal/system/businessCollege/listBusinessCollege',
    method: 'post',
    data: data
  });
}
// 保存商学院
function saveBusinessCollege(data) {
  return (0, _request.default)({
    url: '/portal/system/businessCollege/saveBusinessCollege',
    method: 'post',
    data: data
  });
}
// 更新商学院
function updateBusinessCollege(data) {
  return (0, _request.default)({
    url: '/portal/system/businessCollege/updateBusinessCollege',
    method: 'post',
    data: data
  });
}