var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "data_market_number" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "fc item_box" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "ml10" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "34px" } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dataList.memberData.total.registeredCount) +
                    "\n          "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "400",
                  "font-size": "12px",
                  color: "#969696",
                },
              },
              [
                _vm._v("\n            总会员数/下单会员:\n            "),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dataList.memberData.total.orderedCount) +
                    "\n          "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fc item_box" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "ml10" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "34px" } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dataList.orderData.total.orderAmount) +
                    "\n          "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "400",
                  "font-size": "12px",
                  color: "#969696",
                },
              },
              [_vm._v("\n            总付款订单金额\n          ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fc item_box" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "ml10" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "34px" } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dataList.orderData.total.orderCount) +
                    "\n          "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "400",
                  "font-size": "12px",
                  color: "#969696",
                },
              },
              [_vm._v("\n            总订单数\n          ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fc item_box" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "ml10" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "34px" } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dataList.orderData.total.income) +
                    "\n          "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "400",
                  "font-size": "12px",
                  color: "#969696",
                },
              },
              [_vm._v("\n            总预估佣金\n          ")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "frb" }, [
        _c("div", { staticClass: "item_box1" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("今日订单"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen3.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.today.orderCount) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("昨日订单"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen3.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.last.orderCount) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("日订单对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box1" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("今日订单金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.today.orderAmount) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("昨日订单金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.last.orderAmount) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("日订单金额对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box1" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("今日佣金金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.today.income) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("昨日佣金金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.last.income) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("日佣金金额对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box1" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("今日新增会员数"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen1.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.today.registeredCount
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("昨日新增会员数"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen1.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.last.registeredCount
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("日新增会员数对比")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "frb mt20" }, [
        _c("div", { staticClass: "item_box2" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("本月预估佣金"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.month.income) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("上月预估佣金"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.lastMonth.income) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("月预估佣金对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box2" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("本月付款金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.dataList.orderData.month.orderAmount) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("上月付款金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen5.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.orderData.lastMonth.orderAmount
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("月付款金额对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box2" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("本月新增导师"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen6.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.month.guideAddCount
                            ) +
                            "\n                  "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("上月新增导师"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen6.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.lastMonth.guideAddCount
                            ) +
                            "\n                  "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("月新增导师对比")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item_box2" }, [
          _c("div", { staticClass: "frb" }, [
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "600", color: "#ffffff" } },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("本月新增会员"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen1.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.month.registeredCount
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "font-weight": "400",
                    "font-size": "14px",
                    color: "#ffffff",
                  },
                },
                [
                  _c("div", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("上月新增会员"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: require("@/assets/dataMarket/screen1.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dataList.memberData.lastMonth.registeredCount
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "compare" }, [_vm._v("月新增会员对比")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item_box_div fc",
        staticStyle: { background: "#ffb359" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/dataMarket/screen1.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item_box_div fc",
        staticStyle: { background: "#82a5ff" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/dataMarket/screen2.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item_box_div fc",
        staticStyle: { background: "#ff7e44" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/dataMarket/screen3.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item_box_div fc",
        staticStyle: { background: "#4ecf5f" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/dataMarket/screen4.png"), alt: "" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }