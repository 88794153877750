"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activitySetting = require("@/api/operationManagement/activitySetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {},
  data: function data() {
    return {
      id: '',
      ruleForm: {
        radio: 0,
        num: '',
        money: '',
        time: null
      },
      rules: {
        num: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
        money: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
        time: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    this.getts();
  },
  methods: {
    getts: function getts() {
      var _this = this;
      (0, _activitySetting.getSetting)({}).then(function (res) {
        _this.ruleForm.radio = res.data.enable;
        _this.ruleForm.time = [res.data.startTime, res.data.endTime];
        _this.ruleForm.money = res.data.subsidyAmt;
        _this.ruleForm.num = res.data.subsidyNum;
        _this.appId = res.data.appId;
        _this.id = res.data.id;
      });
    },
    times: function times() {},
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _activitySetting.saveSetting)({
            enable: _this2.ruleForm.radio,
            startTime: _this2.ruleForm.time[0],
            endTime: _this2.ruleForm.time[1],
            subsidyAmt: _this2.ruleForm.money,
            subsidyNum: _this2.ruleForm.num,
            id: _this2.id
          }).then(function (res) {
            if (res.data) {
              _this2.$notify.success({
                title: '温馨提示',
                message: '操作成功'
              });
              _this2.getts();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};