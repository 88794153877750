"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserManage = _interopRequireDefault(require("./components/UserManage.vue"));
var _BlacklistUser = _interopRequireDefault(require("./components/BlacklistUser.vue"));
var _RiskUser = _interopRequireDefault(require("./components/RiskUser.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import OrderInquiry from './components/OrderInquiry.vue'
var _default = exports.default = {
  name: 'MemberList',
  components: {
    UserManageVue: _UserManage.default,
    BlacklistUser: _BlacklistUser.default,
    RiskUser: _RiskUser.default
  },
  data: function data() {
    return {
      activeName: 'first'
    };
  }
};