"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _officialEventBeltShare = _interopRequireDefault(require("./components/officialEventBeltShare.vue"));
var _CustomActivityPopup = _interopRequireDefault(require("./components/CustomActivityPopup.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import CustomActivity from './components/CustomActivity.vue'
// import officialEventNotShare from './components/officialEventNotShare.vue'
// import PolymerizationActivity from './components/PolymerizationActivity.vue'
var _default = exports.default = {
  name: 'LittleActivityCreation',
  components: {
    CustomActivityPopup: _CustomActivityPopup.default,
    officialEventBeltShare: _officialEventBeltShare.default
  },
  // components: { CustomActivity, CustomActivityPopup, officialEventBeltShare, officialEventNotShare, PolymerizationActivity },
  data: function data() {
    return {
      selectID: 0,
      typeName: [{
        id: 0,
        name: '自定义活动'
      }
      // { id: 1, name: '官方活动（有分享）' },
      // { id: 2, name: '官方活动（无分享）' }
      // { id: 3, name: '聚合页活动' }
      ]
    };
  },
  methods: {
    onTypeName: function onTypeName(val) {
      this.selectID = val;
    },
    onAdd: function onAdd() {
      this.$refs.customActivityPopup.open({
        type: 'add',
        name: '添加活动'
      });
    },
    onRefresh: function onRefresh() {
      // console.log('Refresh,刷新页面')

      // location.reload()
      this.$refs.officialEventBeltShare.listActivityApi();
    }
  }
};