"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'ItemActivity',
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      colorObj: {
        '0': '#F8AE13',
        '1': '#52C41A',
        '2': '#BBBBBB'
      },
      textObj: {
        '0': '未开始',
        '1': '进行中',
        '2': '已结束'
      }
    };
  },
  methods: {
    onControls: function onControls(val) {
      this.$emit('controls', {
        type: val,
        item: this.item
      });
    },
    copy: function copy(val) {
      var _this = this;
      this.$copyText(val).then(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝成功'
        });
      }).catch(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝失败，请手动复制'
        });
      });
    }
  }
};